.nav-item, .nav-link {
    cursor: pointer;
}

.table td, .table th, .table tbody tr:last-child td {
    border-width: 1px;
    text-align: center;
    width: 10%;
}

.checkmark {
    cursor: pointer;
    top: -10px;
    display: block;
    position: relative;
    width: 100%;
    heigth: 100%;
    z-index: 1;
}

.pick-cell {
    display: inline-block;
    width: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.rounded-circle {
    width: 30px;
    margin-right: 20px;
}

.team-logo {
    width: 50px;
    top: 14px;
    left: 20px;
    position: relative;
}

.logo-rank {
    float: left;
    position: relative;
}

.rank {
    padding: 0.3em 0.5em;
    margin-right: 3px;
}

.team {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
}

.score {
    position: relative;
    top: 20px;
}

.seed {
    color: #fff;
}

.final {
    border: 1px solid #afa;
}

.live {
    border: 1px solid #faa;
}

.winner {
    font-weight: 700;
}

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.live-tv {
    position: relative;
    top: 3px;
}
.form-control {
    background-color: #adadad !important;
    color: black !important;
    padding-left: 10px;

}
table#picks-table {
    border-spacing: 0;
    border-collapse: collapse;
    overflow: hidden;
}
#picks-table tr:hover{
    background-color: rgba(246, 246, 181, 0.08);
}

#picks-table td, #picks-table th {
    padding: 10px;
    position: relative;
}
#picks-table td:hover::after, #picks-table th:hover::after {
    background-color: rgba(246, 246, 181, 0.08);
    content: '\00a0';
    height: 10000px;
    left: 0;
    position: absolute;
    top: -5000px;
    width: 100%;
}